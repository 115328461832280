import { FlowDataDoc, Step, StepType } from '@arcadehq/shared/types'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { useMemo } from 'react'
import { Flow, FlowPublicData } from 'src/types'

import {
  getFromSerialized,
  getSerializable,
  SerializableData,
} from './serializable'

export function getSerializablePublicFlow(
  flow: Flow
): SerializableData<FlowPublicData> {
  return getSerializable(
    pick(flow, [
      'id',
      'created',
      'modified',
      'name',
      'description',
      'aspectRatio',
      'cta',
      'steps',
      'createdBy',
      'editors',
      'status',
      'flowWrapper',
      'font',
      'bgImage',
      'group',
      'showArcadeButton',
      'openingAnimation',
      'belongsToTeam',
      'showFlowNavIndicator',
      'optimizeFlowForMobileView',
      'showStartOverlay',
      'startOverlayButtonText',
      'preventIndexing',
      'structureHash',
    ])
  )
}

export function getFlowFromSerializablePublicFlow(
  publicFlow: SerializableData<FlowPublicData>
): Flow {
  const unserializedFlow = getFromSerialized<FlowPublicData>(publicFlow)
  return {
    ...unserializedFlow,
    schemaVersion: '',
    experiments: [],
    experimentsConfig: {},
    gifUrl: '',
    videoUrl: '',
    checksum: '',
    tagIds: [],
    publishedDate: new Date(),
    lastModifiedBy: '',
  }
}

export function useFlowFromSerializedPublicData(
  publicFlow: SerializableData<FlowPublicData>
): Flow {
  return useMemo(
    () => getFlowFromSerializablePublicFlow(publicFlow),
    [publicFlow]
  )
}

// TODO: Ideally we should share the `Flow` type between apps
// and not have to do this.
export function getFlowDataDocFromFlow(flow: Flow): FlowDataDoc {
  return {
    ...omit(flow, ['id', 'created', 'modified', 'createdBy', 'lastModifiedBy']),
  }
}

export const getFirstStepWithSize = (steps: Step[]) => {
  return steps.find(step =>
    [StepType.Video, StepType.Image].includes(step.type)
  )
}
